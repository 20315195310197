.tms-ag-table-container {
  height: 100%;
  width: 100%;
}

.ag-table-error {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tms-ag-table {
  height: 100%;
  width: 100%;
  display: none;
}

/**
 ****************************
 * Generic Styles
 ****************************
*/
.ag-theme-balham-dark {
  font-family: inherit;
}
.ag-theme-balham-dark .ag-header {
  background-color: transparent;
  font-family: inherit;
  border-bottom: 1px solid #111;
}
.ag-theme-balham-dark .ag-column-select-panel .ag-primary-cols-list-panel {
  border-top: 0px;
}

.ag-theme-balham-dark .ag-ltr .ag-cell span:first-of-type {
  padding-left: 0 !important;
}

.ag-theme-balham-dark .ag-side-bar .ag-column-drop {
  min-height: 200px;
}
.ag-theme-balham-dark .ag-tool-panel-wrapper .ag-column-drop {
  border-bottom: 1px solid #424242;
  border-top: 1px solid #424242;
}
.ag-theme-balham-dark .ag-column-drop-horizontal.ag-column-drop {
  border: 1px solid #1e2440;
  border: 0;
  height: 35px;
}
.ag-theme-balham-dark .ag-column-drop-horizontal {
  background-color: transparent;
}

.ag-theme-balham-dark .ag-root {
  border: 0;
  border-top: 1px solid #1e2440;
}

.ag-theme-balham-dark .ag-side-bar {
  border: 0;
  border-left: 1px solid #1e2440;
}

.ag-theme-balham-dark .ag-side-bar .ag-side-buttons .ag-selected button {
  background-color: #05070b;
  border-color: #3c4b78;
}

.ag-theme-balham-dark .ag-tool-panel-wrapper {
  border-right: 1px solid #3c4b78;
}

.ag-theme-balham-dark .ag-icon-checkbox-checked,
.ag-theme-balham-dark .ag-icon-checkbox-indeterminate,
.ag-theme-balham-dark .ag-icon-checkbox-unchecked {
  background-color: #05070b;
}
.ag-theme-balham-dark input,
.ag-theme-balham-dark select {
  background-color: #090c15;
  color: #f5f5f5;
}
.ag-theme-balham-dark .ag-icon-checkbox-checked {
  color: #6a82ca;
}

.ag-theme-balham-dark .ag-icon-checkbox-unchecked:before {
  color: #1e2440;
}

.ag-theme-balham-dark .ag-select-agg-func-popup {
  background: #2d3436;
  background: #121625;
}
.ag-theme-balham-dark .ag-menu,
.ag-theme-balham-dark .ag-theme-balham-dark.ag-dnd-ghost,
.ag-theme-balham-dark .ag-cell.ag-cell-inline-editing,
.ag-theme-balham-dark .ag-popup-editor,
.ag-theme-balham-dark .ag-select-agg-func-popup,
.ag-theme-balham-dark .ag-overlay-loading-center {
  border: 1px solid #3c4b78;
}

.ag-theme-balham-dark .ag-filter-toolpanel-body {
  background-color: #121625;
}

.ag-theme-balham-dark
  .ag-tool-panel-wrapper
  .ag-filter-panel
  .ag-filter-toolpanel-instance {
  font-weight: normal;
}

.ag-theme-balham-dark .ag-tool-panel-wrapper .ag-filter-panel .ag-filter-air {
  border: 1px solid #3c4b78;
}

.ag-theme-balham-dark .ag-input-wrapper input:not([type]),
.ag-theme-balham-dark .ag-input-wrapper input[type="text"],
.ag-theme-balham-dark .ag-input-wrapper input[type="number"],
.ag-theme-balham-dark .ag-input-wrapper input[type="tel"],
.ag-theme-balham-dark .ag-input-wrapper input[type="date"],
.ag-theme-balham-dark .ag-input-wrapper input[type="datetime-local"] {
  border-width: 1px;
  border-style: solid;
  border-color: #3c4b78;
}

.ag-theme-balham-dark .ag-row {
  border-color: #3c4b78;
}
.ag-theme-balham-dark .ag-row-even {
  background-color: rgba(0, 0, 0, 0.4);
}

.ag-theme-balham-dark .ag-status-bar {
  background: transparent;
  border: 0;
  border-top: 1px solid #111;
  font-family: inherit;
}

.ag-row-hover {
  /* putting in !important so it overrides the theme's styling as it hovers the row also */
  background-color: #3c4b788f !important;
  transition: 0.5s;
}

.ag-column-hover {
  background-color: #3c4b788f;
  transition: 0.5s;
}

.ag-theme-balham-dark .ag-tab-body,
.ag-theme-balham-dark .ag-popup-editor,
.ag-theme-balham-dark .ag-menu {
  background-color: #111626;
  color: #f5f5f5;
}

.ag-theme-balham-dark .ag-tab-header {
  border-bottom: 1px solid #05070b;
}

.ag-theme-balham-dark .ag-header {
  font-weight: normal;
}

.ag-theme-balham-dark .ag-tab-header {
  background: #0c101b;
}

.ag-theme-balham-dark .ag-tab-header .ag-tab.ag-tab-selected {
  background-color: #111625;
  border-bottom-color: transparent;
}
.ag-theme-balham-dark .ag-tab-header .ag-tab.ag-tab-selected {
  border-bottom: 2px solid #3b4b78;
  border-color: #3b4b78;
}

.ag-theme-balham-dark .ag-menu .ag-menu-option-active {
  background-color: #05070b;
}

.ag-theme-balham-dark .ag-menu .ag-menu-option-icon {
  text-align: center;
}

.ag-theme-balham-dark .ag-menu .ag-menu-option-disabled .ag-menu-option-icon {
  display: none;
}

.ag-theme-balham-dark .ag-menu .ag-menu-option-disabled .ag-menu-option-text {
  padding-left: 10px;
}

.js-reporting-buttons {
  max-height: 40px;
}

.js-ag-grid-action-bar-right .js-tail-select {
  z-index: 1;
  min-width: 225px;
  max-width: 300px;
}
